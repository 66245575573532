import React, { useEffect, useState } from 'react';
import View from '../../../components/View/View';
import Card from '../../../components/Card/Card';
import c, { marketplaceCreateSteps, marketplaceEditSteps } from '../../../helpers/constants';
import { fetchEntries, fetchEntryIdsByMarketplace } from '../../../actions/Entry';
import { useDispatch, useSelector } from 'react-redux';
import { getEntries, isFetchingEntries } from '../../../selectors/Entry';
import { formatUrl, getActiveClient } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { Alert } from 'react-bootstrap';
import Stepper from '../../../components/Stepper';
import {
  getMarketplaceEntries,
  isFetchingMarketplaceEntries,
  isPostingMarketplaceEntry,
  postingMarketplaceEntryError,
  postingMarketplaceEntryFailed,
} from '../../../selectors/MarketplaceEntry';
import { getActiveMarketplace } from '../../../selectors/Marketplace';
import { fetchActiveMarketplace, storeMarketplace } from '../../../actions/Marketplace';
import { useEditMode } from '../../../hooks/EditModeHook';
import Button from '../../../components/Button';
import { createOrUpdateMarketplaceEntry } from '../../../actions/MarketplaceEntry';
import './MarketplaceWizard.scss';
import Modal from '../../../components/Modal/Modal';
import DataTable from '../../../components/DataTable';

const StepTwo = () => {

  const dispatch = useDispatch();
  const entries = useSelector(getEntries);
  const history = useHistory();
  const isPosting = useSelector(isPostingMarketplaceEntry);
  const isLoadingMarketplaceEntries = useSelector(isFetchingMarketplaceEntries);
  const isLoadingEntries = useSelector(isFetchingEntries);
  const activeMarketplace = useSelector(getActiveMarketplace);
  const postingFailed = useSelector(postingMarketplaceEntryFailed);
  const postingError = useSelector(postingMarketplaceEntryError);
  const marketplaceEntries = useSelector(getMarketplaceEntries);
  const isFetching = isLoadingMarketplaceEntries || isLoadingEntries;

  const [selectedEntryIds, setSelectedEntryIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [createMode, activeId ] = useEditMode(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_TWO);
  const [editMode, activeEditId ] = useEditMode(c.APP_ROUTES.MARKETPLACE_EDIT_STEP_TWO);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const steps = createMode ? marketplaceCreateSteps : marketplaceEditSteps;
  const entryIds = entries?.data?.map(entry => entry.id);
  const clientId = getActiveClient().id;

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'viewTitle', label: 'View', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30],
  };

  useEffect(() => {
    if (createMode) {
      dispatch(fetchActiveMarketplace(activeId));
    }
    if (editMode && activeEditId){
      dispatch(fetchActiveMarketplace(activeEditId));
      dispatch(fetchEntryIdsByMarketplace({ fetchParams:{ 'id' : activeEditId } }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (editMode && activeEditId){
      setSelectedEntryIds(marketplaceEntries?.data.map(marketplaceEntry => marketplaceEntry.entryId) || []);
    }
  }, [marketplaceEntries]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      if (createMode){
        history.push(formatUrl(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_THREE, activeId));
      } else {
        history.push(formatUrl(c.APP_ROUTES.MARKETPLACE_EDIT_STEP_THREE, activeEditId));
      }

    }
  }, [isPosting]);


  const onNavigate = (entryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId));
  };

  const handleCardSelect = (entryId: string) => {
    if (selectedEntryIds.includes(entryId)) {
      setSelectedEntryIds(selectedEntryIds.filter(id => id !== entryId));
    } else {
      setSelectedEntryIds([...selectedEntryIds, entryId]);
    }
  };

  const handleSelectAll = (select: boolean) => {
    if (select && entryIds) {
      setSelectedEntryIds(entryIds);
      setSelectAll(true);
    } else {
      setSelectedEntryIds([]);
      setSelectAll(false);
    }
  };

  const onSubmit = async () => {
    if (activeMarketplace) {
      setHasSubmitted(true);
      dispatch(createOrUpdateMarketplaceEntry(activeMarketplace.id, selectedEntryIds));
    }
  };

  const handlePopstate = () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleBrowserBack = async () => {
    await storeMarketplace(activeId);
    history.push(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_ONE);
  };

  return (
        <View title='Select Items For Marketplace'>
            <Modal show={showConfirmationModal}
                   title={'Are you sure you want to leave? All your progress will be lost'}
                   onHide={handleModalClose}>
                <Button title="Yes, I am sure" onClick={handleBrowserBack}/>
                {' '}
                <Button title="No" onClick={handleModalClose}/>
            </Modal>
            <div className="container-fluid">
                {postingError && (
                    <Alert className="mt-3" variant="danger">
                        {postingError}
                    </Alert>
                )}
                <div className="row justify-content-center mb-4">
                    <div className="col-8">
                        <Stepper steps={steps} activeStep={createMode ? 1 : 0} hideMobileLabels={true}/>
                    </div>
                </div>

                <div className="mx-4">
                    <div className='row'>
                        <div className="col-md-12 marketplace-wizard__box--grey">
                            <div className='row'>
                                <div className='row my-4 justify-content-end'>
                                    <div className="col-10 col-md-3 align-items-center justify-content-center">
                                        <CheckBox label='Select All Items' type='checkbox' checked={selectAll} onChange={handleSelectAll}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <DataTable
                                        paging={entries?.paging}
                                        isLoading={isFetching}
                                        data={entries?.data}
                                        config={config}
                                        fetchFunction={fetchEntries}
                                        baseSearch={{ 'clientId': clientId }}
                                        isTable={false}
                                        lineItemTemplate={(row: any) => (
                                            <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>

                                                <Card
                                                    itemId={row.id}
                                                    cardId={row.index}
                                                    title={row.title}
                                                    description={row.description}
                                                    imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                                                    onClick={() => onNavigate(row.id)}
                                                    selectable={true}
                                                    onSelect={handleCardSelect} // Pass the callback function
                                                    isSelected={selectedEntryIds.includes(row.id)}
                                                />
                                            </div>

                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <Button disabled={selectedEntryIds.length < 1} isLoading={isPosting || isFetching}
                                onClick={() =>  onSubmit()}
                                title={ 'Next'}/>
                    </div>
                </div>
            </div>
        </View>
  );
};

export default StepTwo;

