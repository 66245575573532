import React, { useEffect, useState } from 'react';
import './Subscription.scss';
import Button from '../../components/Button';
import EMEModal from '../../components/Modal';
import { postSubscriptionTransaction } from '../../api/Subscription';
import { getPlans } from '../../selectors/Plan';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlans } from '../../actions/Plan';
import { createSubscription, fetchSubscriptions } from '../../actions/Subscription';
import { getSubscriptions } from '../../selectors/Subscription';
import { getActiveClient } from '../../helpers/functions';

interface TransactionResponse {
  id: string;
  redirect_url: string;
  amount: number;
  status: string;
  return_url: string;
  payment_method: string;
}

const Subscription = () => {
  const plans = useSelector(getPlans);
  const subscriptions = useSelector(getSubscriptions);
  const dispatch = useDispatch();
  const [transactionData, setTransactionData] = useState<TransactionResponse | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [subPlan, setSubPlan] = useState('');
  const [returnUrl, setReturnUrl] = useState('');
  const client = getActiveClient();


  useEffect(()=>{
    if (client && client.id){
      dispatch(fetchPlans());
      dispatch(fetchSubscriptions({ baseSearch: { 'clientId': client?.id, 'active': true } }));
    }
  }, [client?.id]);
  
  const handleSelectTier = async (planId: string, tier: string, fee: number) => {
    setSubPlan(planId);
    if (client && client.id) {
      const asyncResp = await postSubscriptionTransaction(planId, client?.id, tier, fee);
      console.log(asyncResp, 'response');
      if (asyncResp?.success && asyncResp?.data && asyncResp?.data?.payment_method === 'free') {
        setReturnUrl(asyncResp?.data?.return_url);
        setTransactionData(asyncResp?.data);
        setShowModal(true); // Open modal after transaction is created
      } else if (asyncResp?.success && asyncResp?.data && asyncResp?.data?.payment_method !== 'free'){
        setTransactionData(asyncResp?.data);
        setReturnUrl('');
        setShowModal(true);
      }
    }
  };
  console.log(returnUrl, 'returnUrl');

  const handleModalClose = () => setShowModal(false);
  
  const handleProceedToBank = () => {
    console.log(returnUrl, client?.id, transactionData);
    if (transactionData  && !returnUrl && client?.id) {
      dispatch(createSubscription(subPlan, client?.id, transactionData.id, '', '', false ));
      window.location.href = transactionData?.redirect_url; // Redirect to OPP payment page
    } else if (returnUrl && client?.id && transactionData){
      dispatch(createSubscription(subPlan, client?.id, transactionData.id, '', '', false ));
      window.location.href = returnUrl;
    }
  };

  const activePlanTitle = subscriptions?.data?.[0]?.planTitle;
  
  return (
        <div className="subscription-container">
            <h1>Select Your Subscription Tier</h1>
                <div className="subscription-container__card-container">
                    {plans && plans.data
                      .sort((a, b) => a.monthlyFee - b.monthlyFee) // Sort by monthlyFee in ascending order
                      .map((plan) =>
                            <div key={plan.id} className={!(activePlanTitle === plan.title) ? 'subscription-container__card' : 'subscription-container__card subscription-container__card-selected'} onClick={() => (!(activePlanTitle === plan.title)) && handleSelectTier(plan.id, plan.title, plan.monthlyFee)}>
                                <h2>{plan.title}</h2>
                                <p className="subscription-container__card-container__text">
                              {plan.description.replace(/[\[\],"]/g, '')
                                .split('-')
                                .map((item) => item.trim())
                                .filter((trimmedItem) => trimmedItem !== '')
                                .map((trimmedItem, index) =>
                                    <div className="row" key={index}>
                                      <p>{trimmedItem}</p>
                                    </div>)}
                                </p>
                                <p>Price: {plan.monthlyFee} EUR</p>
                            </div>,
                      )
                    }
                </div>
            {activePlanTitle && (
                <div className="selected-tier">
                    <h3>Your active tier is: {activePlanTitle}</h3>
                </div>
            )}
            <EMEModal
                show={showModal}
                onHide={handleModalClose}
                title= {((transactionData && transactionData?.redirect_url) || returnUrl) ? (!returnUrl ? 'You will be redirected to a safe payments environment' : 'Finalise your subscription') : 'Something went wrong'}
            >
                {((transactionData && transactionData?.redirect_url) || returnUrl)  ?
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <Button onClick={handleModalClose} title="Cancel"></Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleProceedToBank} title="Proceed"></Button>
                    </div>
                </div> :
                    <div className="row">
                        <div className="col-auto">
                            Refresh the page and try again, if the issue persist contact an administrator.
                        </div>
                    </div> }
            </EMEModal>
        </div>
  );
};

export default Subscription;
