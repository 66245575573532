import { useLocation } from 'react-router-dom';
import axios from 'axios';

import { FormError } from '../types/Common';
import { RefObject, useEffect, useState } from 'react';
import { Entry } from '../types/Entry';
import { formatInTimeZone } from 'date-fns-tz';
import c from '../helpers/constants';
import { User } from '../types/User';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../selectors/Auth';

const lPrefix = 'MATERIALS_';

export const objectToQueryString: any = (obj: any, prefix: string) => {
  let str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push((v !== null && typeof v === 'object') ?
        objectToQueryString(v, k) :
        encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }
  return str.join('&');
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const localStorageSetItem = (key: string, value: string) => {
  window.localStorage.setItem(`${lPrefix}${key}`, value);
};

export const localStorageGetItem = (key: string) => {
  return window.localStorage.getItem(`${lPrefix}${key}`);
};

export const getToken =  () => {
  return localStorageGetItem('token') || '';
};

export const setToken = (token: string) => {
  localStorageSetItem('token', token);
};

export const getUserToken =  () => {
  return localStorageGetItem('activeUser') || '';
};

export function countMatches(row: any, fields:string[], searchWord: string) {
  return fields.reduce((totalMatches, field) => {
    const fieldValue = row?.[field];
    if (fieldValue) {
      const fieldMatches = fieldValue.match(new RegExp(searchWord, 'gi')) || [];
      return totalMatches + fieldMatches.length;
    }
    return totalMatches;
  }, 0);
}

// Function to remove duplicate entries out of string array

export function removeDuplicates(array: string[]): string[]{
  return array.filter((item, index) => array.indexOf(item) === index);
}

export function generatePassword() {
  const length = 8;
  const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()-_=+';

  const getRandomChar = (pool : any) => {
    return pool.charAt(Math.floor(Math.random() * pool.length));
  };

  let password = '';
  password += getRandomChar(letters); // At least one uppercase letter
  password += getRandomChar(letters).toLowerCase(); // At least one lowercase letter
  password += getRandomChar(numbers); // At least one digit
  password += getRandomChar(symbols); // At least one symbol

  for (let i = 0; i < length - 4; i++) {
    const pool = letters + numbers + symbols;
    password += getRandomChar(pool);
  }

  // Shuffle the password to randomize the order of characters
  password = password.split('').sort(() => Math.random() - 0.5).join('');

  return password;
}



// 2023-12-15 15:30:000 -> 2023-12-15T15:30:00.000Z
export const convertToLocal = (dateTimeString: string) => {
  const [datePart, timePart] = dateTimeString.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hour, minute, second] = timePart.split(':');
  const utcDateTime = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second)));
  const utcString = utcDateTime.toISOString();
  return formatInTimeZone(utcString, Intl.DateTimeFormat().resolvedOptions().timeZone, 'yyyy-MM-dd HH:mm:ss');
};

export const ensurePositiveInteger = (val: string) => {
  const regex = /^[1-9]\d*$/;
  if (regex.test(val)) {
    return val;
  } else if (val === '' || val === '0') {
    return '';
  } else {
    const positiveInteger = val.replace(/[^0-9]/g, '');
    return (positiveInteger || '');
  }
};

export const setUserToken = (user: string) => {
  localStorageSetItem('activeUser', user);
};

// export const setLocalVersion = (manVer: string) => {
//   localStorageSetItem('version', manVer);
// };

export const getLocalVersion = () => {
  return localStorageGetItem('version') || '';
};


// @ts-ignore
export function formatUrl(path, ...vars) {
  const matches = path.match(/(:[a-z])\w+/g);
  let newString = path;
  // @ts-ignore
  matches.forEach((v, i) => {
    if (vars[i]) {
      newString = newString.replace(v, vars[i]);
      newString = newString.replace('?', '');
    } else {
      newString = newString.replace(`/${v}`, '');
      newString = newString.replace('?', '');
    }
  });
  return newString;
}

export const belongsToMyClient = (user: User, clientId: string) => {
  return user?.clients.some(client => client.clientId === clientId);
};

export function latLngCalculation(mapPrivateItems: { geolocation: string, id: string }[], mapPublicItems: { geolocation: string, id: string }[] ){
  // check if geolocation is a valid float number
  const publicLatList = mapPublicItems?.map( x =>  parseFloat(x?.geolocation.split('~')[0]));
  const privateLatList = mapPrivateItems?.map( x =>  parseFloat(x?.geolocation.split('~')[0]));
  // Map lng calculations
  const publicLngList = mapPublicItems?.map( x =>  parseFloat(x?.geolocation.split('~')[1]));
  const privateLngList = mapPrivateItems?.map( x =>  parseFloat(x?.geolocation.split('~')[1]));
  let  publicLat = 52.1312863;
  let privateLat = 52.1312863;
  let  publicLng = -0.0544277;
  let privateLng = -0.0544277;
  if (publicLatList && !isNaN(publicLatList[0])  && publicLatList.length > 0) {
    publicLat = publicLatList[0];
  }
  if (privateLatList && !isNaN(privateLatList[0])  && privateLatList.length > 0) {
    privateLat = privateLatList[0];
  }
  if (publicLngList && !isNaN(publicLngList[0])  && publicLngList.length > 0) {
    publicLng = publicLngList[0];
  }
  if (privateLngList && !isNaN(privateLngList[0])  && privateLngList.length > 0) {
    privateLng = privateLngList[0];
  }

  return {
    publicLat,
    privateLat, 
    publicLng, 
    privateLng };
}

export function parseValidationErrors(rawErrors: any) {
  if (!rawErrors) {
    return [];
  }
  const errors: FormError[] = [];
  Object.keys(rawErrors).forEach(k => {
    errors.push({
      field: k,
      errors: rawErrors[k],
    });
  });
  return errors;
}

export function formatTime(milliseconds: number) {
  if (!milliseconds) {
    return 'N/A';
  }
  if (milliseconds < 0) {
    return '0d 0h 0m 0s';
  }
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

export const downloadQRCode = (qrCodeRef: RefObject<HTMLDivElement>) => {
  try {
    if (qrCodeRef && qrCodeRef.current) {
      const qrCodeSVG = qrCodeRef.current.querySelector('svg');

      if (qrCodeSVG) {
        const svgString = new XMLSerializer().serializeToString(qrCodeSVG);
        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'qrcode.svg';
        link.click();

        window.URL.revokeObjectURL(url);
      }
    }
  } catch (error) {
    console.error('Error downloading QR code:', error);
  }
};

export function generateQRCodeValue(providedEntry: Entry) {
  return formatUrl(c.API_ENDPOINTS.QR_REDIRECT, providedEntry.groupShortcode, providedEntry.clientShortcode, providedEntry.id);
}

export const getRateColor = (rate?: number | string, col?: string): string | undefined => {
  if (col === 'minRate' || col === 'maxRate' || col === 'actualRate' || col === 'total' || col === undefined){
    if (rate === undefined || rate === null) {
      return '#000000'; // Default color if no rate is provided
    }
    return rate < 0 ? 'green' : rate > 0 ? 'red' : undefined;
  }
};

axios.interceptors.request.use((config) => {
  /** In dev, intercepts request and logs it into console for dev */
  // console.info('✉️ ', config);
  const token = getToken();
  // console.log(token);
  // @ts-ignore
  config.headers.Authorization =  `Bearer ${token}`;
  return config;
}, (error) => {
  console.error('✉️ ', error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) { //AKA Unauthorised
    // window.location.href = c.APP_ROUTES.USER_LOGIN;
    // localStorage.setItem('returnUrl', location.pathname);
    // console.error('You are not authorised for this resource. Please login');
  }
  return Promise.reject(error);
});



function getMediaQuery(size: number) {
  return matchMedia(`(max-width: ${size}px)`);
}

export function useBreakpoint(size: number): boolean {
  const [isMatched, setIsMatched] = useState(() => {
    return getMediaQuery(size).matches;
  });

  useEffect(() => {
    const query = getMediaQuery(size);

    const handler = () => setIsMatched(query.matches);

    query.addEventListener('change', handler);
    handler();

    return () => {
      query.removeEventListener('change', handler);
    };
  }, [size]);

  return isMatched;
}

export const hasActiveSubscription = (user: any): boolean => {
  // Check if user and user.clients are defined
  if (!user || !user.clients) {
    return false;
  }

  // Check if any client has an activeSubscription
  return user.clients.some((client: { activeSubscription: null; }) => client.activeSubscription !== null);
};

export const getActiveGroup = () => {
  const activeUser = useSelector(getLoggedInUser);

  const groupId = localStorage.getItem('selectedGroupId') || activeUser?.groups[0].groupId;
  const groupTitle = localStorage.getItem('selectedGroupTitle') || activeUser?.groups[0].groupTitle;

  return {
    id: groupId,
    title: groupTitle,
  };
};

export const getActiveClient = () => {
  const activeUser = useSelector(getLoggedInUser);

  const clientId = localStorage.getItem('selectedClientId') || activeUser?.clients[0].clientId;
  const clientTitle = localStorage.getItem('selectedClientTitle') || activeUser?.clients[0].clientTitle;

  return {
    id: clientId,
    title: clientTitle,
  };
};

export function useMobile() {

  return useBreakpoint(768);
}

