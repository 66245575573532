import React from 'react';
import { useSelector } from 'react-redux';
import { getOPPNotifications, isFetchingOPPNotifications } from '../../../selectors/OPPNotification';
import View from '../../../components/View';
import DataTable from '../../../components/DataTable';
import { fetchOPPNotifications } from '../../../actions/OPPNotification';

const OppNotificationDataTable = () => {
  const OPPNotifications = useSelector(getOPPNotifications);
  const isLoading = useSelector(isFetchingOPPNotifications);

  const config = {
    columns: [
      { key: 'notificationId', label: 'Notification ID', mobileFriendly: true },
      { key: 'type', label: 'Transaction Type', mobileFriendly: true },
      { key: 'objectType', label: 'Type', mobileFriendly: true },
      { key: 'objectUid', label: 'Transaction ID', mobileFriendly: true },
      { key: 'created', label: 'Created At', mobileFriendly: false },
    ],
    actions: [],
    hideButton: true,
  };
  return (
      <View title="OPP Notifications">
          <DataTable
              fetchFunction={fetchOPPNotifications}
              data={OPPNotifications?.data || []}
              isLoading={isLoading}
              paging={OPPNotifications?.paging || []}
              config={config}
          />
      </View>
  );
};

export default OppNotificationDataTable;
