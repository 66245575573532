import React, { useEffect, useState } from 'react';
import Input from '../../../components/Input';
import View from '../../../components/View/View';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFetchingMarketplaces,
  isPostingMarketplace,
  postingMarketplaceError,
  postingMarketplaceFailed, postingMarketplaceValidationErrors,
} from '../../../selectors/Marketplace';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { createMarketplaceFromSteps } from '../../../actions/Marketplace';
import c, { marketplaceCreateSteps, marketplaceStatuses } from '../../../helpers/constants';
import Button from '../../../components/Button';
import { Alert } from 'react-bootstrap';
import FileSelector from '../../../components/FileSelector';
import Stepper from '../../../components/Stepper';
import './MarketplaceWizard.scss';
import CheckBox from '../../../components/CheckBox';
import DropDown from '../../../components/DropDown';
import { getActiveClient, getActiveGroup } from '../../../helpers/functions';

const StepOne = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingMarketplace);
  const isFetching = useSelector(isFetchingMarketplaces);
  const postingFailed = useSelector(postingMarketplaceFailed);
  const postingError = useSelector(postingMarketplaceError);
  const postingValidationErrors = useSelector(postingMarketplaceValidationErrors);

  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
    {  field: 'visibility', validators: [isRequired] },
  ];
  const steps = marketplaceCreateSteps;

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assetId, setAsset] = useState('');
  const [assetError, setAssetError] = useState('');
  const [visibility, setVisibility] = useState('');
  const [visibilityError, setVisibilityError] = useState('');
  const [active, setActive] = useState(true);

  const groupId = getActiveGroup().id;
  const clientId = getActiveClient().id;

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
    }
  }, [isPosting]);

  useEffect(() => {
    if (assetId) {
      setAssetError('');
    }
  }, [assetId]);

  useEffect(() => {
    if (visibility) {
      setVisibilityError('');
    }
  }, [visibility]);

  const onSubmit = async () => {
    if (triggerValidation() && groupId && clientId) {
      if (assetId && visibility) {
        setHasSubmitted(true);
        dispatch(createMarketplaceFromSteps(groupId, clientId, title, description, assetId, active, visibility, history));
      } else {
        if (!assetId) {
          setAssetError('Please select an asset for your marketplace.');
        }
        if (!visibility) {
          setVisibilityError('Please select a visibility setting for your marketplace.');
        }
      }
    }
  };

  const onCancel = async () => {
    history.push(c.APP_ROUTES.MARKETPLACES);
  };

  const onVisibilitySelect = (obj: any) => {
    setVisibility(obj.value);
  };

  const handleFilePick = (id: any) => {
    setAsset(id);
  };

  const handleFileUnPick = () => {
    setAsset('');
  };

  const itemsStatuses = marketplaceStatuses?.map(r => ({ label: r, value: r })) || [];

  return (
        <View title="Provide Marketplace Details">
            <div className="container-fluid">
                {postingError && (
                    <Alert className="mt-3" variant="danger">
                        {postingError}
                    </Alert>
                )}
                {assetError && (
                    <Alert className="mt-3" variant="danger">
                        {assetError}
                    </Alert>
                )}
                {visibilityError && (
                    <Alert className="mt-3" variant="danger">
                        {visibilityError}
                    </Alert>
                )}
                <div className="row justify-content-center mb-4">
                    <div className="col-8">
                        <Stepper steps={steps} activeStep={0} hideMobileLabels={true}/>
                    </div>
                </div>
                <form>
                    <div className="mx-4">
                    <div className="row ">
                        <div className='col-md-12 marketplace-wizard__box'>
                            <div className='row'>
                            <div className="col-md-6">
                                <Input v={v} err={err}
                                       name="title"
                                       id="title"
                                       label="Title"
                                       required
                                       value={title}
                                       onChange={setTitle}
                                       disabled={isPosting || isFetching}
                                       onBlur={setTitle}
                                       placeholder="Insert a title"
                                />
                            </div>
                            <div className="col-md-6">
                                <Input v={v} err={err}
                                       name="description"
                                       id="description"
                                       label="Description"
                                       required
                                       value={description}
                                       onChange={setDescription}
                                       disabled={isPosting || isFetching}
                                       onBlur={setDescription}
                                       placeholder="Insert a description"
                                />
                            </div>
                            </div>
                        </div>
                        <div className='col-md-12 marketplace-wizard__image-container'>
                            <div className="marketplace-wizard__image-container__label">{'Primary Image*'}</div>
                            <FileSelector
                                type="images"
                                groupId={groupId}
                                clientId={clientId}
                                dynamicId = {'primary'}
                                onPick={handleFilePick}
                                onUnPick={handleFileUnPick}
                                picked={assetId}
                            />
                        </div>

                            <div className='col-md-12 marketplace-wizard__box'>
                                <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <DropDown v={v} err={err}
                                          id="visibility"
                                          placeholder="Select a visibility"
                                          required
                                          label="Visibility"
                                          items={itemsStatuses}
                                          disabled={isPosting || isFetching}
                                          value={visibility}
                                          onSelect={onVisibilitySelect}
                                          type="default"
                                />
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <Button  onClick={onCancel} title='Cancel'/>
                            </div>
                            <div className="col-auto">
                                <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                        onClick={() =>  onSubmit()}
                                        title={ 'Next'}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </View>
  );
};

export default StepOne;

