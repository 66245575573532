import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_RESPONSE,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_RESPONSE,
  FETCH_SUBSCRIPTION_FAILURE,
  POST_SUBSCRIPTION_REQUEST,
  POST_SUBSCRIPTION_RESPONSE,
  POST_SUBSCRIPTION_FAILURE,
  SubscriptionActionTypes,
  SubscriptionDataModel,
  Subscription,
} from '../types/Subscription';

import { RootState } from '../store/configureStore';

import { archiveSubscription, getSubscription, getSubscriptions, postSubscription, putSubscription } from '../api/Subscription';
import { DataTableParamsModel } from '../types/Common';

export function fetchSubscriptionsRequest(): SubscriptionActionTypes {
  return {
    type: FETCH_SUBSCRIPTIONS_REQUEST,
    payload: null,
  };
}

export function fetchSubscriptionsResponse(
  subscriptions: SubscriptionDataModel,
): SubscriptionActionTypes {
  return {
    type: FETCH_SUBSCRIPTIONS_RESPONSE,
    payload: subscriptions,
  };
}

export function fetchSubscriptionsFailure(): SubscriptionActionTypes {
  return {
    type: FETCH_SUBSCRIPTIONS_FAILURE,
    payload: null,
  };
}

export function fetchSubscriptionRequest(): SubscriptionActionTypes {
  return {
    type: FETCH_SUBSCRIPTION_REQUEST,
    payload: null,
  };
}

export function fetchSubscriptionResponse(
  subscription: Subscription,
): SubscriptionActionTypes {
  return {
    type: FETCH_SUBSCRIPTION_RESPONSE,
    payload: subscription,
  };
}

export function fetchSubscriptionFailure(): SubscriptionActionTypes {
  return {
    type: FETCH_SUBSCRIPTION_FAILURE,
    payload: null,
  };
}

export function postSubscriptionRequest(): SubscriptionActionTypes {
  return {
    type: POST_SUBSCRIPTION_REQUEST,
    payload: null,
  };
}

export function postSubscriptionResponse(): SubscriptionActionTypes {
  return {
    type: POST_SUBSCRIPTION_RESPONSE,
    payload: null,
  };
}

export function postSubscriptionFailure(error: string, validationErrors: any): SubscriptionActionTypes {
  return {
    type: POST_SUBSCRIPTION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchSubscriptions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSubscriptionsRequest());
    const asyncResp: any = await getSubscriptions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchSubscriptionsResponse(asyncResp));
    } else {
      await dispatch(fetchSubscriptionsFailure());
    }
  };

export const fetchActiveSubscription = (id: string, dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSubscriptionRequest());
    const asyncResp: any = await getSubscription(id, dataTableParams);
    if (asyncResp?.success) {
      await dispatch(fetchSubscriptionResponse(asyncResp.data));
    } else {
      await dispatch(fetchSubscriptionFailure());
    }
  };

export const createSubscription = (
  planId: string,
  clientId: string,
  transactionId: string,
  startDate: string,
  endDate: string | null,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSubscriptionRequest());
    const asyncResp: any = await postSubscription(planId, clientId, transactionId, startDate, endDate, active);
    if (asyncResp?.success) {
      await dispatch(postSubscriptionResponse());
    } else {
      await dispatch(postSubscriptionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateSubscription = (
  id: string,
  planId: string,
  clientId: string,
  transactionId: string,
  startDate: string,
  endDate: string | null,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSubscriptionRequest());
    const asyncResp: any = await putSubscription(id, planId, clientId, transactionId, startDate, endDate, active);
    if (asyncResp?.success) {
      await dispatch(postSubscriptionResponse());
    } else {
      await dispatch(postSubscriptionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeSubscription = async (id: string) =>{
  try {
    await archiveSubscription(id);
  } catch (error) {
    console.error('Failed to archive subscription:', error);
  }
};
