import { ApiDataModel, FormError } from './Common';

// Action Types
export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_RESPONSE = 'FETCH_SUBSCRIPTIONS_RESPONSE';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_FAILURE';

export const FETCH_SUBSCRIPTION_REQUEST = 'FETCH_SUBSCRIPTION_REQUEST';
export const FETCH_SUBSCRIPTION_RESPONSE = 'FETCH_SUBSCRIPTION_RESPONSE';
export const FETCH_SUBSCRIPTION_FAILURE = 'FETCH_SUBSCRIPTION_FAILURE';

export const POST_SUBSCRIPTION_REQUEST = 'POST_SUBSCRIPTION_REQUEST';
export const POST_SUBSCRIPTION_RESPONSE = 'POST_SUBSCRIPTION_RESPONSE';
export const POST_SUBSCRIPTION_FAILURE = 'POST_SUBSCRIPTION_FAILURE';

// Subscription Interface
export interface Subscription {
  id: string;
  planId: string;
  transactionId: string;
  planTitle:string;
  clientId: string;
  startDate: string;
  endDate: string;
  autoRenewal: boolean;
  transactionStatus: string;
  transactionAmount: string;
  active: boolean;
  created: Date;
  createdBy: string;
}

// Subscription Data Model
export interface SubscriptionDataModel extends Omit<ApiDataModel, 'data'> {
  data: Subscription[];
}

// State Interface
export interface SubscriptionState {
  subscriptions: SubscriptionDataModel | null;
  activeSubscription: Subscription | null;
  isFetchingSubscriptions: boolean;
  fetchingSubscriptionsFailed: boolean;
  postingSubscriptionFailed?: boolean;
  isPostingSubscription: boolean;
  postingSubscriptionValidationErrors: FormError[] | null;
  postingSubscriptionError: string | null;
}

// Action Interfaces
interface FetchSubscriptionsRequestAction {
  type: typeof FETCH_SUBSCRIPTIONS_REQUEST;
  payload: null;
}

interface FetchSubscriptionsResponseAction {
  type: typeof FETCH_SUBSCRIPTIONS_RESPONSE;
  payload: SubscriptionDataModel;
}

interface FetchSubscriptionsFailureAction {
  type: typeof FETCH_SUBSCRIPTIONS_FAILURE;
  payload: null;
}

interface FetchSubscriptionRequestAction {
  type: typeof FETCH_SUBSCRIPTION_REQUEST;
  payload: null;
}

interface FetchSubscriptionResponseAction {
  type: typeof FETCH_SUBSCRIPTION_RESPONSE;
  payload: Subscription;
}

interface FetchSubscriptionFailureAction {
  type: typeof FETCH_SUBSCRIPTION_FAILURE;
  payload: null;
}

interface PostSubscriptionRequestAction {
  type: typeof POST_SUBSCRIPTION_REQUEST;
  payload: null;
}

interface PostSubscriptionResponseAction {
  type: typeof POST_SUBSCRIPTION_RESPONSE;
  payload: null;
}

interface PostSubscriptionFailureAction {
  type: typeof POST_SUBSCRIPTION_FAILURE;
  payload: {
    error?: string;
    validationErrors?: any;
  };
}

// Action Types
export type SubscriptionActionTypes =
    | FetchSubscriptionsRequestAction
    | FetchSubscriptionsResponseAction
    | FetchSubscriptionsFailureAction
    | PostSubscriptionRequestAction
    | PostSubscriptionResponseAction
    | PostSubscriptionFailureAction
    | FetchSubscriptionRequestAction
    | FetchSubscriptionResponseAction
    | FetchSubscriptionFailureAction;
