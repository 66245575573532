import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLoggedInUser, getLoggedInRole } from '../../selectors/Auth';
import DropDown from '../../components/DropDown';
import './ClientSelector.scss';

type Item = {
  value: string | number;
  label?: string;
  type?: string;
};

interface ClientSelectorProps {
  onClientSelect: (newTitle: string) => void;
}

const ClientSelector: React.FC<ClientSelectorProps> = ({ onClientSelect }) => {
  const loggedInUser = useSelector(getLoggedInUser);
  const loggedInRole = useSelector(getLoggedInRole);

  const [clientId, setClientId] = useState(
    localStorage.getItem('selectedClientId') || (loggedInUser?.clients ? loggedInUser.clients[0].clientId : ''),
  );
  const [groupId, setGroupId] = useState(
    localStorage.getItem('selectedGroupId') || (loggedInUser?.groups ? loggedInUser.groups[0].groupId : ''),
  );

  const [itemsClients, setItemsClients] = useState<Item[]>(
    loggedInUser?.clients.map(r => ({ label: r.clientTitle, value: r.clientId })) || [],
  );

  const itemsGroups: Item[] = loggedInUser?.groups.map(group => ({
    label: group.groupTitle,
    value: group.groupId,
  })) || [];

  useEffect(() => {
    if (loggedInUser && loggedInUser.clients) {
      setItemsClients(
        loggedInUser?.clients
          ?.filter(data => data.clientGroupId.includes(groupId))
          .map(r => ({ label: r.clientTitle, value: r.clientId })) || [],
      );
    }
  }, [loggedInUser, clientId, groupId]);

  const handleClientSelect = (selectedClient: Item) => {
    setClientId(selectedClient.value.toString());
    localStorage.setItem('selectedClientId', selectedClient.value.toString());
    localStorage.setItem('selectedClientTitle', selectedClient.label || '');

    onClientSelect(selectedClient.label || '');
    window.location.reload();
  };

  const handleGroupSelect = (selectedGroup: Item) => {
    setGroupId(selectedGroup.value.toString());
    setItemsClients(
      loggedInUser?.clients
        ?.filter(data => data.clientGroupId.includes(selectedGroup.value.toString()))
        .map(r => ({ label: r.clientTitle, value: r.clientId })) || [],
    );
    localStorage.setItem('selectedGroupId', selectedGroup.value.toString());
    localStorage.setItem('selectedGroupTitle', selectedGroup.label || '');
  };

  return (
      <div>
        {loggedInUser && loggedInRole && loggedInUser?.clients.length > 1 && loggedInRole.accessLevel <= 1000 && (
            <div className="client-selector">
              {loggedInUser?.groups.length > 1 && (
                  <DropDown
                      id="groupId"
                      placeholder="Please select a Group"
                      label="Group"
                      items={itemsGroups}
                      value={groupId}
                      onSelect={(e) => handleGroupSelect(e)}
                      type="default"
                  />
              )}
              <DropDown
                  id="clientId"
                  placeholder="Please select a Client"
                  label="Client"
                  items={itemsClients}
                  value={clientId}
                  onSelect={(e) => handleClientSelect(e)}
                  type="default"
              />
            </div>
        )}
      </div>
  );
};

export default ClientSelector;
