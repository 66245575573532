import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createView, fetchActiveView, updateView } from '../../../actions/View';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { getForms } from '../../../selectors/Form';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import { fetchForms } from '../../../actions/Form';
import {
  getActiveView,
  isPostingView,
  postingViewError,
  postingViewFailed,
  postingViewValidationErrors,
  isFetchingViews,
} from '../../../selectors/View';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';


const ViewForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeView = useSelector(getActiveView);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const forms = useSelector(getForms);
  const isPosting = useSelector(isPostingView);
  const isFetching = useSelector(isFetchingViews);
  const postingFailed = useSelector(postingViewFailed);
  const postingError = useSelector(postingViewError);
  const postingValidationErrors = useSelector(postingViewValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.VIEW_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'formId', validators: [] },
    { field: 'order', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'contents', validators: [] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [formId, setFormId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [order, setOrder] = useState(0);
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [itemsForms, setItemsForms] = useState(forms?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);
  const [disabledForm, setDisabledForms] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchForms());
    if (editMode) {
      dispatch(fetchActiveView(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeView && editMode) {
      setGroupId(activeView?.groupId);
      setClientId(activeView?.clientId);
      setFormId(activeView?.formId);
      setOrder(activeView?.order);
      setTitle(activeView?.title);
      setContents(activeView?.contents);
      // @ts-ignore
      setActive(activeView?.active === 1 || activeView?.active === true);
    }
  }, [activeView]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    setItemsForms(forms?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [forms]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.VIEW);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createView(groupId, clientId, formId, order, title, contents, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateView(activeId, groupId, clientId, formId, order, title, contents, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
    setItemsForms(forms?.data.filter(data => data.clientId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledForms(false);
  };

  const onFormSelect = (obj: any) => {
    setFormId(obj.value);
  };

  return (
    <View>
      <h4>{editMode ? 'Edit' : 'Create'} View</h4>
      {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
      )}
      <form>
        <DropDown v={v} err={err}
          id="groupId"
          placeholder="Please select a Group"
          required label="Group"
          items={itemsGroups}
          value={groupId}
          disabled={isPosting || isFetching}
          onSelect={onGroupSelect}
          type="default"
        />
        <DropDown v={v} err={err}
        id="clientId"
        placeholder="Please select a Client"
        required
        label="Client"
        items={itemsClients}
        disabled={isPosting || isFetching || disabledClient}
        value={clientId}
        onSelect={onClientSelect}
        type="default"
      />
      <DropDown v={v} err={err}
        id="formId"
        placeholder="Please select a Form"
        label="Form"
        items={itemsForms}
        disabled={isPosting || isFetching || disabledForm}
        value={formId}
        onSelect={onFormSelect}
        type="default"
      />
        <Input v={v} err={err}
               name="order"
               id="order"
               label="Order"
               required
               value={order}
               onChange={setOrder}
               disabled={isPosting || isFetching}
               onBlur={setOrder}
               placeholder="Insert an order value"
        />
        <Input v={v} err={err}
          name="title"
          id="title"
          label="Title"
          required
          value={title}
          onChange={setTitle}
          disabled={isPosting || isFetching}
          onBlur={setTitle}
          placeholder="Insert a title"
        />
        <Input v={v} err={err}
        name="contents"
        id="contents"
        label="Contents"
        value={contents}
        disabled={isPosting || isFetching}
        onChange={setContents}
        onBlur={setContents}
        placeholder="Insert your content"
      />
        <CheckBox label="Is Active?" checked={active} onChange={setActive} />
        <div className="row">
          <div className="col-md-4">
            <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
              onClick={() => editMode ? onSave() : onSubmit()}
              title={editMode ? 'SAVE' : 'CREATE'} />
          </div>
        </div>
      </form>
    </View>
  );

};

export default ViewForm;
