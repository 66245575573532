import React, { useEffect, useState } from 'react';
import View from '../../../components/View/View';
import Card from '../../../components/Card/Card';
import c from '../../../helpers/constants';
import { fetchEntries } from '../../../actions/Entry';
import { useDispatch, useSelector } from 'react-redux';
import { getEntries, isFetchingEntries } from '../../../selectors/Entry';
import { formatUrl, getActiveClient } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { Alert } from 'react-bootstrap';
import Stepper from '../../../components/Stepper';
import {
  isPostingAuctionEntry,
  postingAuctionEntryError,
  postingAuctionEntryFailed,
} from '../../../selectors/AuctionEntry';
import { getActiveAuction, isFetchingAuctions } from '../../../selectors/Auction';
import { fetchActiveAuction, storeAuction } from '../../../actions/Auction';
import { useEditMode } from '../../../hooks/EditModeHook';
import Button from '../../../components/Button';
import { createBatchAuctionEntries } from '../../../actions/AuctionEntry';
import './AuctionWizard.scss';
import Modal from '../../../components/Modal/Modal';
import DataTable from '../../../components/DataTable';

const StepTwo = () => {

  const dispatch = useDispatch();
  const entries = useSelector(getEntries);
  const history = useHistory();
  const isPosting = useSelector(isPostingAuctionEntry);
  const isLoadingAuctions = useSelector(isFetchingAuctions);
  const isLoadingEntries = useSelector(isFetchingEntries);
  const activeAuction = useSelector(getActiveAuction);
  const postingFailed = useSelector(postingAuctionEntryFailed);
  const postingError = useSelector(postingAuctionEntryError);
  const isFetching = isLoadingAuctions || isLoadingEntries;
  const clientId = getActiveClient().id;

  const steps = [
    'Provide Auction Details',
    'Select Items For Auction',
    'Overview',
    'Success',
  ];

  const [selectedEntryIds, setSelectedEntryIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const entryIds = entries?.data.map(entry => entry.id);
  const [editMode, activeId ] = useEditMode(c.APP_ROUTES.AUCTION_CREATE_STEP_TWO);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'viewTitle', label: 'View', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30],
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveAuction(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(formatUrl(c.APP_ROUTES.AUCTION_CREATE_STEP_THREE, activeId));
    }
  }, [isPosting]);


  const onNavigate = (entryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId));
  };

  const handlePopstate = () => {
    setShowConfirmationModal(true);
  };
  
  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleBrowserBack = async () => {
    await storeAuction(activeId);
    history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_ONE);
  };

  const handleCardSelect = (entryId: string) => {
    if (selectedEntryIds.includes(entryId)) {
      setSelectedEntryIds(selectedEntryIds.filter(id => id !== entryId));
    } else {
      setSelectedEntryIds([...selectedEntryIds, entryId]);
    }
  };

  const handleSelectAll = (select: boolean) => {
    if (select && entryIds) {
      setSelectedEntryIds(entryIds);
      setSelectAll(true);
    } else {
      setSelectedEntryIds([]);
      setSelectAll(false);
    }
  };

  const onSubmit = async () => {
    if ( activeAuction?.groupId && activeAuction?.clientId) {
      setHasSubmitted(true);
      dispatch(createBatchAuctionEntries(activeAuction?.id, selectedEntryIds, activeAuction?.startingBid, activeAuction?.startDateTime, activeAuction?.endDateTime, activeAuction?.bidIncrement));
    }
  };

  return (
      <View title='Select Items For Auction'>
            <Modal show={showConfirmationModal}
                   title={'Are you sure you want to leave? All your progress will be lost'}
                   onHide={handleModalClose}>
              <Button title="Yes, I am sure" onClick={handleBrowserBack}/>
              {' '}
              <Button title="No"  onClick={handleModalClose}/>
            </Modal>
        <div className="container-fluid">
          {postingError && (
              <Alert className="mt-3" variant="danger">
                {postingError}
              </Alert>
          )}
          <div className="row justify-content-center mb-4">
            <div className="col-8">
              <Stepper steps={steps} activeStep={1} hideMobileLabels={true}/>
            </div>
          </div>

          <div className="mx-4">
            <div className='row'>
              <div className="col-md-12 auction-wizard__box--grey">
                <div className='row'>
                    <div className='row my-4 justify-content-end'>
                      <div className="col-3 d-flex align-items-center justify-content-center">
                        <span className="auction-wizard__checkbox-label me-2">Select All Items</span>
                        <CheckBox type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                      </div>
                    </div>
                  <div className='row'>
                    <DataTable
                        paging={entries?.paging}
                        isLoading={isFetching}
                        data={entries?.data}
                        baseSearch={{ 'clientId': clientId }}
                        config={config}
                        fetchFunction={fetchEntries}
                        isTable={false}
                        lineItemTemplate={(row: any) => (
                            <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>

                              <Card
                                  itemId={row.id}
                                  cardId={row.index}
                                  title={row.title}
                                  description={row.description}
                                  imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                                  onClick={() => onNavigate(row.id)}
                                  selectable={true}
                                  onSelect={handleCardSelect} // Pass the callback function
                                  isSelected={selectedEntryIds.includes(row.id)}
                              />
                            </div>

                        )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-auto">
              <Button disabled={selectedEntryIds.length < 1} isLoading={isPosting || isFetching}
                      onClick={() =>  onSubmit()}
                      title={ 'Next'}/>
            </div>
          </div>
        </div>
      </View>
  );
};

export default StepTwo;

