import React, { useEffect } from 'react';
import './AccountInformation.scss';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions, isFetchingSubscriptions } from '../../../selectors/Subscription';
import { fetchSubscriptions } from '../../../actions/Subscription';
import { getActiveClient } from '../../../helpers/functions';
import { Badge } from 'react-bootstrap';
import c from '../../../helpers/constants';
import Loading from '../../../components/Loading/Loading';

const Subscriptions = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(getSubscriptions);
  const isLoading = useSelector(isFetchingSubscriptions);
  const client = getActiveClient();

  useEffect(() => {
    if (client?.id) {
      dispatch(fetchSubscriptions({ baseSearch: { clientId: client.id }, colKey: 'created', sortDirection: 'desc' }));
    }
  }, [client?.id, dispatch]);

  // Get active, pending, and attempted transactions
  // @ts-ignore
  const activeSubscription = subscriptions?.data?.find(sub => sub.active === 1);
  const pendingSubscription = subscriptions?.data?.find(sub => sub.transactionStatus === 'pending');
  // @ts-ignore
  const attemptedTransactions = subscriptions?.data?.filter(sub => sub.active === 0);

  const onUpgrade = () => window.open(c.APP_ROUTES.SUBSCRIPTION, '_blank');

  if (isLoading || !subscriptions) return <Loading />;

  return (
      <div className="container p-4">
        {pendingSubscription ? (
            <div className="row">
              <div className="col">
                <h3 className="text-warning">Pending Subscription</h3>
                <p>Your subscription is currently pending. Please wait for confirmation.</p>
              </div>
            </div>
        ) : (
            <>
              {activeSubscription ? (
                  <div className="row mb-3">
                    <div className="col">
                      <h3>
                        Active Subscription:
                        <Badge bg={activeSubscription.planTitle === 'Free' ? 'primary' : 'warning'} className="ml-2">
                          {activeSubscription.planTitle}
                        </Badge>
                      </h3>
                      {activeSubscription.planTitle === 'Free' && (
                          <Button onClick={onUpgrade} title="Upgrade Subscription" />
                      )}
                    </div>
                  </div>
              ) : (
                  <div className="row mb-3">
                    <div className="col">
                      <h4>You have not selected a subscription plan!</h4>
                    </div>
                    <div className="col">
                      <Button onClick={onUpgrade} title="Upgrade Subscription" />
                    </div>
                  </div>
              )}

              {attemptedTransactions && attemptedTransactions.length > 0 && (
                  <div className="row">
                    <div className="col">
                      <h4>Transactions</h4>
                      <table className="table">
                        <thead>
                        <tr>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {attemptedTransactions.map(transaction => (
                            <tr key={transaction.id}>
                              <td>{Number(transaction.transactionAmount) / 100}</td>
                              <td>
                          <span className={`text-${transaction.transactionStatus === 'failed' ? 'danger' : 'secondary'}`}>
                            {transaction.transactionStatus}
                          </span>
                              </td>
                              <td>{transaction.created}</td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
              )}
            </>
        )}
      </div>
  );
};

export default Subscriptions;
