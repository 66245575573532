import { ApiDataModel, FormError } from './Common';

// Action Types
export const FETCH_OPP_NOTIFICATIONS_REQUEST = 'FETCH_OPP_NOTIFICATIONS_REQUEST';
export const FETCH_OPP_NOTIFICATIONS_RESPONSE = 'FETCH_OPP_NOTIFICATIONS_RESPONSE';
export const FETCH_OPP_NOTIFICATIONS_FAILURE = 'FETCH_OPP_NOTIFICATIONS_FAILURE';

export const FETCH_OPP_NOTIFICATION_REQUEST = 'FETCH_OPP_NOTIFICATION_REQUEST';
export const FETCH_OPP_NOTIFICATION_RESPONSE = 'FETCH_OPP_NOTIFICATION_RESPONSE';
export const FETCH_OPP_NOTIFICATION_FAILURE = 'FETCH_OPP_NOTIFICATION_FAILURE';

export const POST_OPP_NOTIFICATION_REQUEST = 'POST_SUBSCRIPTION_REQUEST';
export const POST_OPP_NOTIFICATION_RESPONSE = 'POST_SUBSCRIPTION_RESPONSE';
export const POST_OPP_NOTIFICATION_FAILURE = 'POST_SUBSCRIPTION_FAILURE';

export interface OPPNotification {
  id: string;
  notificationId: string;
  type: string;
  objectType:string;
  objectUid: string;
  createdAt: Date;
  createdBy: string;
}

export interface OPPNotificationDataModel extends Omit<ApiDataModel, 'data'> {
  data: OPPNotification[];
}

export interface OPPNotificationState {
  oppNotifications: OPPNotificationDataModel | null;
  activeOPPNotification: OPPNotification | null;
  isFetchingOPPNotifications: boolean;
  fetchingOPPNotificationsFailed: boolean;
  postingOPPNotificationFailed?: boolean;
  isPostingOPPNotification: boolean;
  postingOPPNotificationValidationErrors: FormError[] | null;
  postingOPPNotificationError: string | null;
}


interface FetchOPPNotificationsRequestAction {
  type: typeof FETCH_OPP_NOTIFICATIONS_REQUEST;
  payload: null;
}

interface FetchOPPNotificationsResponseAction {
  type: typeof FETCH_OPP_NOTIFICATIONS_RESPONSE;
  payload: OPPNotificationDataModel;
}

interface FetchOPPNotificationsFailureAction {
  type: typeof FETCH_OPP_NOTIFICATIONS_FAILURE;
  payload: null;
}

interface FetchOPPNotificationRequestAction {
  type: typeof FETCH_OPP_NOTIFICATION_REQUEST;
  payload: null;
}

interface FetchOPPNotificationResponseAction {
  type: typeof FETCH_OPP_NOTIFICATION_RESPONSE;
  payload: OPPNotification;
}

interface FetchOPPNotificationFailureAction {
  type: typeof FETCH_OPP_NOTIFICATION_FAILURE;
  payload: null;
}

interface PostOPPNotificationRequestAction {
  type: typeof POST_OPP_NOTIFICATION_REQUEST;
  payload: null;
}

interface PostOPPNotificationResponseAction {
  type: typeof POST_OPP_NOTIFICATION_RESPONSE;
  payload: null;
}

interface PostOPPNotificationFailureAction {
  type: typeof POST_OPP_NOTIFICATION_FAILURE;
  payload: {
    error?: string;
    validationErrors?: any;
  };
}

// Action Types
export type OPPNotificationActionTypes =
    | FetchOPPNotificationsRequestAction
    | FetchOPPNotificationsResponseAction
    | FetchOPPNotificationsFailureAction
    | PostOPPNotificationRequestAction
    | PostOPPNotificationResponseAction
    | PostOPPNotificationFailureAction
    | FetchOPPNotificationRequestAction
    | FetchOPPNotificationResponseAction
    | FetchOPPNotificationFailureAction;
