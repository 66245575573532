import React, { useEffect, useState } from 'react';
import './Return.scss';
import Loading from '../../components/Loading/Loading';
import { fetchActiveTransaction } from '../../actions/Transaction';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTransaction } from '../../selectors/Transaction';
import View from '../../components/View/View';
import c, { TransactionStatus } from '../../helpers/constants';
import Button from '../../components/Button';


const PaymentStatusPage = () => {
  const activeTransaction = useSelector(getActiveTransaction);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const transactionId = new URLSearchParams(window.location.search).get('transaction_id');
  const onUpgrade = () => window.open(c.APP_ROUTES.SUBSCRIPTION, '_blank');

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('Timeout after 5 seconds');
      if (loading) {
        try {
          dispatch(fetchActiveTransaction(transactionId ?? ''));
          setLoading(false);
        } catch (error) {
          console.error('Error fetching payment status:', error);
          setLoading(false);
        }
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [loading]);

  const renderMessage = () => {
    if (!activeTransaction?.status) return null;

    switch (activeTransaction.status) {
      case TransactionStatus.CREATED:
      case TransactionStatus.PENDING:
      case TransactionStatus.PLANNED:
      case TransactionStatus.RESERVED:
        return (
            <div>
              <h1>Payment is being processed</h1>
              <p>Your payment is being processed. You will receive an email when your subscription is activated.</p>
              {transactionId && (
                  <div>
                    <h2>Receipt</h2>
                    <p>Transaction ID: {transactionId}</p>
                    <p>Amount: {(activeTransaction?.amount / 100).toFixed(2)}</p>
                    <p>Status: {activeTransaction?.status}</p>
                  </div>
              )}
            </div>
        );

      case TransactionStatus.FAILED:
      case TransactionStatus.EXPIRED:
      case TransactionStatus.CANCELLED:
        return (
            <div>
              <h1>Payment Failed</h1>
              <p>Something went wrong with your payment. Please try again. If the issue persists, contact an administrator.</p>
              {transactionId && (
                  <div>
                    <h2>Receipt</h2>
                    <p>Transaction ID: {transactionId}</p>
                    <p>Amount: {(activeTransaction?.amount / 100).toFixed(2)}</p>
                    <p>Status: {activeTransaction?.status}</p>
                  </div>
              )}
              <Button onClick={onUpgrade} title="Upgrade Subscription" />
            </div>
        );

      case TransactionStatus.COMPLETED:
        return (
            <div>
              <h1>Payment Successful!</h1>
              <p>Your subscription has been activated. Thank you for your purchase!</p>
              {transactionId && (
                  <div>
                    <h2>Receipt</h2>
                    <p>Transaction ID: {transactionId}</p>
                    <p>Amount: {(activeTransaction?.amount / 100).toFixed(2)}</p>
                    <p>Status: {activeTransaction?.status}</p>
                  </div>
              )}
            </div>
        );

      default:
        return (
            <div>
              <h1>Unknown Status</h1>
              <p>Please contact support for more information.</p>
            </div>
        );
    }
  };

  return (
      <View>
        {(loading || !activeTransaction?.status) ? (
            <div className="loading-container">
              <Loading text={'Your payment is being processed, please wait!'}/>
            </div>
        ) : (
            <div className="return-container">
              {renderMessage()}
            </div>
        )}
      </View>
  );
};

export default PaymentStatusPage;
