import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../styles/formRenderer.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { useValidation } from '../../hooks/ValidationHook';
import { isRequired } from '../../helpers/validators';

import { Alert } from 'react-bootstrap';
import DropDown from '../../components/DropDown';

import {
  getActiveOffer,
  isFetchingOffers,
  isPostingOffer,
  postingOfferError,
  postingOfferFailed, postingOfferValidationErrors,
} from '../../selectors/Offer';
import { getClients, isPostingClient, postingClientError, postingClientFailed } from '../../selectors/Client';
import { getUsers } from '../../selectors/User';
import { useEditMode } from '../../hooks/EditModeHook';
import { fetchClients } from '../../actions/Client';
import { fetchUsers } from '../../actions/User';
import { fetchContacts } from '../../actions/Contact';
import { fetchSolutions } from '../../actions/Solution';
import { createOffer, fetchActiveOffer, updateOffer } from '../../actions/Offer';
import TextArea from '../../components/TextArea';
import DateSelect from '../../components/DateSelect';
import MaterialMap from '../../components/MaterialMap';
import assetGallery from '../../components/AssetGallery/AssetGallery';
import Modal from '../../components/Modal';
import ClientForm from './OfferNewClient';
import ProjectManagerForm from './CreateContact';
import CheckBox from '../../components/CheckBox';
import { getContacts, isPostingContact, postingContactError, postingContactFailed } from '../../selectors/Contact';
import ItemsTable from '../../components/Offer/ItemsTable/ItemsTable';
import { DataTableParamsModel } from '../../types/Common';
import { fetchOfferItems } from '../../actions/OfferItem';
import { getOfferItems } from '../../selectors/OfferItem';
import { getLoggedInUser } from '../../selectors/Auth';
import { getActiveClient, getActiveGroup } from '../../helpers/functions';

interface Row {
  id: string;
  entryId: string;
  details: string;
  quantity: number;
  minRate: number;
  maxRate: number;
  actualRate?: number;
  total?: number;
  statuses?: string[];
}

function CreateOffer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeOffer = useSelector(getActiveOffer);
  const clients = useSelector(getClients);
  const users = useSelector(getUsers);
  const offerItems = useSelector(getOfferItems);
  const contacts = useSelector(getContacts);
  const initialLat = 48.1312863;
  const initialLng = 4.1659645;
  const initialZoom = 4;
  const initialAddress = 'Europe';
  const [editMode, activeId] = useEditMode(c.APP_ROUTES.REVIEW_OFFER);
  const createMode = useRouteMatch(c.APP_ROUTES.ADD_NEW_OFFER);
  const [isValid, setIsValid] = useState(false);

  const [pickupLocation, setPickupLocation] = useState(`${initialLat}~${initialLng}~${initialZoom}~${initialAddress}`);

  // Extract lat, lng, zoom, and address from pickupLocation state
  const [lat, lng, zoom, address] = pickupLocation.split('~').map((part, index) => {
    if (index < 3) return parseFloat(part);
    return part;
  }) as [number, number, number, string];

  const isPosting = useSelector(isPostingOffer);
  const isPostingClients = useSelector(isPostingClient);
  const isPostingContacts = useSelector(isPostingContact);
  const postingContactsError = useSelector(postingContactError);
  const postingContactsFailure = useSelector(postingContactFailed);
  const postingClientsError = useSelector(postingClientError);
  const postingClientsFailure = useSelector(postingClientFailed);
  const isFetching = useSelector(isFetchingOffers);
  const postingFailed = useSelector(postingOfferFailed);
  const postingError = useSelector(postingOfferError);
  const postingValidationErrors = useSelector(postingOfferValidationErrors);
  const loggedInUser = useSelector(getLoggedInUser);

  const groupId = getActiveGroup().id;
  const clientId = getActiveClient().id;

  const formConfig = [
    { field: 'subject', validators: [isRequired] },
    { field: 'details', validators: [] },
    { field: 'date', validators: [] },
    { field: 'clientNumber', validators: [isRequired] },
    { field: 'currency', validators: [isRequired] },
    { field: 'pickupLocation', validators: [] },
    { field: 'collectionDate', validators: [] },
    { field: 'notesToEME', validators: [] },
    { field: 'notesToShippingProvider', validators: [] },
    { field: 'salesRepUserId', validators: [] },
    { field: 'shipmentContactId', validators: [] },
    { field: 'donorClientId', validators: [isRequired] },
    { field: 'projectManagerUserId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [subject, setSubject] = useState('');
  const [details, setDetails] = useState('');

  const [date, setDate] = useState(new Date());
  const [collectionDate, setCollectionDate] = useState(new Date());
  const [clientNumber, setClientNumber] = useState('');
  const [currency, setCurrency] = useState<string>('GBP');
  const [notesToEME, setNotesToEME] = useState('');
  const [notesToShippingProvider, setNotesToShippingProvider] = useState('');
  const [salesRepUserId, setSalesRepUserId] = useState('');
  const [donorClientId, setDonorClientId] = useState('');
  const [projectManagerUserId, setProjectManagerUserId] = useState('');

  const [itemsClients, setItemsClients] = useState<{ label: string; value: string; }[]>([]);
  const [itemsUsersContacts, setItemsUsersContacts] = useState<{ label: string; value: string; }[]>([]);
  const [createdOfferItems, setCreatedOfferItems] = useState<Row[]>([]);

  const [hideMap, setHideMap] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [creatingNewClient, setCreatingNewClient] = useState(false);
  const [creatingNewProjectManager, setCreatingNewProjectManager] = useState(false);
  const [selfShipping, setSelfShipping] = useState(false);
  const [solutionId, setSolutionId] = useState('');
  const [, setShipmentContactId] = useState('');
  
  const currencies = [
    { value: 'USD', label: 'US Dollar' },
    { value: 'EUR', label: 'Euro' },
    { value: 'GBP', label: 'British Pound' },
    { value: 'JPY', label: 'Japanese Yen' },
    // Add more currencies as needed
  ];

  const handleCurrencySelect = (item: any) => {
    setCurrency(item.value);
  };

  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const latestStatusCode = activeOffer?.statusCodes[0]?.value ?? '';
  const isVerificationStep = latestStatusCode === 'Pending Verification';

  useEffect(() => {
    const clientsParams: DataTableParamsModel = {
      sortDirection: 'desc',
      paging: '100',
      searchField: 'groupId',
      searchWord: groupId,
    };
    dispatch(fetchClients(clientsParams));
    dispatch(fetchUsers());
    dispatch(fetchContacts());
    dispatch(fetchSolutions());

    const offerItemsDataTableParams: DataTableParamsModel = {
      sortDirection: 'desc',
      paging: '100',
      searchField: 'offerId',
      searchWord: activeId ?? 'undefined', //fallback to string that will return no items
    };
    dispatch(fetchOfferItems(offerItemsDataTableParams));

    if (editMode) {
      dispatch(fetchActiveOffer(activeId));
    }

  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (clients) {
      setItemsClients(clients.data.map(client => ({ label: client.title, value: client.id })));
    }
  }, [clients]);

  useEffect(() => {
    if (contacts && users) {
      const usersItems = users.data.map(user => ({ label: user.email, value: user.id }));
      const contactsItems = contacts.data.map(contact => ({ label: contact.email, value: contact.id }));
      setItemsUsersContacts([...usersItems, ...contactsItems]);
    }
  }, [contacts, users]);

  useEffect(() => {
    if (activeOffer && editMode) {
      setSubject(activeOffer?.subject);
      setDetails(activeOffer?.details);
      setClientNumber(activeOffer?.clientNumber);
      setCurrency(activeOffer?.currency);
      setPickupLocation(activeOffer?.pickupLocation);
      setDate(new Date(activeOffer?.date));
      setCollectionDate(new Date(activeOffer?.collectionDate));
      setNotesToEME(activeOffer?.notesToEME);
      setNotesToShippingProvider(activeOffer?.notesToShippingProvider);
      setSalesRepUserId(activeOffer?.salesRepUserId);
      setShipmentContactId(selfShipping ? 'selfShipping' : '');
      setSolutionId(activeOffer?.solutionId);
      setDonorClientId(activeOffer?.donorClientId);
      setProjectManagerUserId(activeOffer?.projectManagerUserId);
      setSelfShipping(activeOffer?.shipmentContactId === 'selfShipping');
    }
  }, [activeOffer, editMode]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.DEAL_HUB);
    }
  }, [isPosting]);

  const validatedOfferItems = createdOfferItems.map(item => ({
    ...item,
    statuses: item.statuses ?? [],
  }));

  const onConfirmSubmit = () => {
    if (triggerValidation()) {
      let newStatus = 'Pending Verification';

      if (!createMode && editMode && activeOffer) {
        switch (latestStatusCode) {
          case 'Draft':
            newStatus = 'Pending Verification';
            break;
          case 'Pending Verification':
            newStatus = 'Verification Completed';
            // newStatus = 'Pending Estimation';
            break;
          case 'Pending Estimation':
            newStatus = 'Estimation Completed';
            // newStatus = 'Pending Offer';
            break;
          default:
            newStatus = 'Pending Verification';
            break;
        }
      }

      if (createMode) {
        setHasSubmitted(true);
        dispatch(createOffer(
          donorClientId,
          projectManagerUserId,
          loggedInUser?.id ?? salesRepUserId,
          selfShipping ? 'selfShipping' : '',
          solutionId,
          subject,
          details,
          date.toISOString(),
          clientNumber,
          currency,
          pickupLocation,
          collectionDate.toISOString(),
          notesToEME,
          notesToShippingProvider,
          newStatus,
          validatedOfferItems,
        ));
      } else if (editMode) {
        setHasSubmitted(true);
        dispatch(updateOffer(
          activeId,
          donorClientId,
          projectManagerUserId,
          salesRepUserId,
          selfShipping ? 'selfShipping' : '',
          solutionId,
          subject,
          details,
          date.toISOString(),
          clientNumber,
          currency,
          pickupLocation,
          collectionDate.toISOString(),
          notesToEME,
          notesToShippingProvider,
          newStatus,
          '',
          validatedOfferItems,
        ));
      }
    }
  };

  const onDraft = async () => {
    let draftStatus = 'Draft';
    if (!createMode && editMode && activeOffer) {
      draftStatus = latestStatusCode; // Maintain current status for draft
    }

    if (createMode) {
      setHasSubmitted(true);
      dispatch(createOffer(
        donorClientId,
        projectManagerUserId,
        loggedInUser?.id ?? salesRepUserId,
        selfShipping ? 'selfShipping' : '',
        solutionId,
        subject,
        details,
        date.toISOString(),
        clientNumber,
        currency,
        pickupLocation,
        collectionDate.toISOString(),
        notesToEME,
        notesToShippingProvider,
        draftStatus,
        validatedOfferItems,
      ));
    } else if (editMode) {
      setHasSubmitted(true);
      dispatch(updateOffer(
        activeId,
        donorClientId,
        projectManagerUserId,
        salesRepUserId,
        selfShipping ? 'selfShipping' : '',
        solutionId,
        subject,
        details,
        date.toISOString(),
        clientNumber,
        currency,
        pickupLocation,
        collectionDate.toISOString(),
        notesToEME,
        notesToShippingProvider,
        draftStatus,
        '',
        validatedOfferItems,
      ));
    }
  };


  const onCancel = async () => {
    history.push(c.APP_ROUTES.DEAL_HUB);
  };

  const onClientSelect = (obj: any) => {
    setDonorClientId(obj.value);
  };

  const onProjectManagerSelect = (obj: any) => {
    setProjectManagerUserId(obj.value);
  };

  const onToggleMapInput = () => {
    setHideMap(!hideMap);
  };

  const handleMapChange = (la: number, ln: number, zm: number, adr: string) => {
    const adjustedZoom = Math.min(zm, 12); // Adjust the zoom level to a maximum of 12
    setPickupLocation(`${la}~${ln}~${adjustedZoom}~${adr}`);
    setHideMap(true);
  };

  const onCreateNewClient = () => {
    setShowModal(true);
    setCreatingNewClient(true);
  };

  const onCreateNewProjectManager = () => {
    setShowModal(true);
    setCreatingNewProjectManager(true);
  };

  const onDismissModal = () => {
    setShowModal(false);
    setCreatingNewClient(false);
    setCreatingNewProjectManager(false);
    setConfirmSubmit(false);
  };

  const onSubmit = () => {
    if (triggerValidation()) {
      setShowModal(true);
      setConfirmSubmit(true);
    } else {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
  };

  useEffect(() => {
    if (!isPostingClients) {
      if (postingClientsFailure || postingClientsError ){
        console.log('Posting Error');
      } else {
        onDismissModal();
        dispatch(fetchClients());
      }

    }
  }, [isPostingClients]);

  useEffect(() => {
    if (!isPostingContacts) {
      if (postingContactsFailure || postingContactsError ){
        console.log('Posting Error');
      } else {
        onDismissModal();
        dispatch(fetchContacts());
      }
    }
  }, [isPostingContacts]);

  const handleRowsUpdate = (updatedRows: Row[]) => {
    setCreatedOfferItems(updatedRows);
    setIsValid(!updatedRows.some(row => Math.abs(Number(row.minRate)) > Math.abs(Number(row.maxRate))));
  };

  const handleCollectionDate = (obj: any) => {
    if (date && obj && obj >= date) {
      setCollectionDate(obj);
    } else {
      setCollectionDate(date);
    }
  };

  const handleOfferDate = (obj: any) => {
    setDate(obj);

    if (obj && (!collectionDate || collectionDate < obj)) {
      setCollectionDate(obj);
    }
  };


  return (
            <View title={
              !isFetching
                ? latestStatusCode === 'Pending Verification'
                  ? 'VERIFY OFFER'
                  : 'ADD NEW OFFER'
                : ''
                }
                  isLoading={isFetching || isPosting}>
                <div className="page">
                     {postingError && (
                    <Alert className="mt-3" variant="danger">
                        {postingError}
                    </Alert>
                     )}
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="category__label">Client Details</div>
                                <div className="col-md-12 category__container">
                                    <DropDown v={v} err={err}
                                              id="donorClientId"
                                              placeholder="Please select a Client"
                                              required label="From"
                                              items={itemsClients}
                                              value={donorClientId}
                                              disabled={isPosting || isFetching}
                                              onSelect={onClientSelect}
                                              type="default"
                                    />
                                    <span className="action-link" onClick={onCreateNewClient}>New Client</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="category__label">Project Manager Details</div>
                                <div className="col-md-12 category__container">
                                    <DropDown v={v} err={err}
                                              id="projectManagerUserId"
                                              placeholder="Please select a Project Manager"
                                              required label="Project Manager"
                                              items={itemsUsersContacts}
                                              value={projectManagerUserId}
                                              disabled={isPosting || isFetching}
                                              onSelect={onProjectManagerSelect}
                                              type="default"
                                    />
                                    <span className="action-link" onClick={onCreateNewProjectManager}>New Project Manager</span>
                                  <p className="mt-2 mb-0"><strong>* Provide the Project Manager&apos;s information before verifying your offer.</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="category__label">Offer Details</div>
                        <div className="col-md-12 category__container">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input v={v} err={err}
                                           name="subject"
                                           id="subject"
                                           label="Offer Subject"
                                           required
                                           value={subject}
                                           onChange={setSubject}
                                           disabled={isPosting || isFetching}
                                           onBlur={setSubject}
                                           placeholder="Insert a subject"
                                    />
                                    <DateSelect
                                        onChange={handleOfferDate}
                                        id="date"
                                        title="Offer Date"
                                        minDateTime={new Date()}
                                        value={date}
                                        required
                                        v={v} err={err}
                                    />
                                    <Input v={v} err={err}
                                           required
                                           name="clientNumber"
                                           id="clientNumber"
                                           type="tel"
                                           label="Offer Number ABB"
                                           value={clientNumber}
                                           disabled={isPosting || isFetching}
                                           onChange={setClientNumber}
                                           onBlur={setClientNumber}
                                           placeholder="Insert the client number"
                                    />
                                    <DropDown
                                        v={v} err={err}
                                        name="currency"
                                        id="currency"
                                        label="Currency"
                                        items={currencies}
                                        value={currency}
                                        onSelect={handleCurrencySelect}
                                        placeholder="Select a currency"
                                        required={true}
                                        searchable={true}
                                    />
                                    <DateSelect
                                        onChange={handleCollectionDate}
                                        title="Collection Date"
                                        id="collectionDate"
                                        minDateTime={date}
                                        value={collectionDate}
                                        required
                                        err={err}
                                        v={v}
                                    />
                                </div>
                                <div className="col-md-6 category__container">
                                    <TextArea v={v} err={err}
                                              name="details"
                                              id="details"
                                              label="Offer Details"
                                              value={details}
                                              disabled={isPosting || isFetching}
                                              onChange={setDetails}
                                              onBlur={setDetails}
                                              placeholder="Insert details"
                                    />
                                    {(address && address != 'Europe') && <span className="location-text my-2 me-2">
                                            <img src={assetGallery.pinSmallImg} alt="pin"
                                                 style={{ marginRight: '5px', marginBottom: '3px' }}/>
                                        {address}
                                        </span>}
                                    <Button title={pickupLocation ? 'Edit Location' : 'Select Location'}
                                            onClick={() => onToggleMapInput()}/>
                                    <MaterialMap
                                        lat={lat}
                                        lng={lng}
                                        zoom={zoom}
                                        address={pickupLocation}
                                        isEntryRenderer={true}
                                        onChange={handleMapChange}
                                        editMode={true}
                                        showOnlyAddress={hideMap}
                                    />
                                </div>
                                <div className="col-md-12 mt-4">
                                    <ItemsTable rowsData={offerItems?.data} columnCount={5} editable={true}
                                                clientId={clientId} groupId={groupId}
                                                onRowsUpdate={handleRowsUpdate} type={'ABB'}/>
                                </div>
                                <div className="col-md-6 category__container">
                                    <TextArea err={err}
                                              name="notesToEME"
                                              id="notesToEME"
                                              label="Notes for EME"
                                              value={notesToEME}
                                              disabled={isPosting || isFetching}
                                              onChange={setNotesToEME}
                                              onBlur={setNotesToEME}
                                              placeholder="Insert text"
                                    />
                                </div>
                                {!selfShipping && <div className="col-md-6 category__container">
                                    <TextArea err={err}
                                              name="notesToShippingProvider"
                                              id="notesToShippingProvider"
                                              label="Notes for Shipment"
                                              value={notesToShippingProvider}
                                              disabled={isPosting || isFetching}
                                              onChange={setNotesToShippingProvider}
                                              onBlur={setNotesToShippingProvider}
                                              placeholder="Insert text"
                                    />
                                </div>}
                            </div>
                        </div>
                        <>
                            <div className="category__label">Shipment Provider Information</div>
                            <div className="col-md-12 category__container">
                                <div className="row">
                                    <CheckBox inverted={true} type="checkbox"
                                              label="BY CHECKING THIS BOX, I AGREE TO TAKE CHARGE OF THE SHIPMENT"
                                              checked={selfShipping} onChange={() => setSelfShipping(!selfShipping)}/>
                                </div>
                            </div>
                        </>


                        <Modal show={showModal} title={!creatingNewClient && !creatingNewProjectManager
                          ? (isVerificationStep ? 'VERIFICATION' : 'SUBMIT FOR VERIFICATION')
                          : undefined
                        }
                               onHide={onDismissModal}>
                            {creatingNewClient && <ClientForm/>}
                            {creatingNewProjectManager && <ProjectManagerForm/>}
                            {confirmSubmit && <>
                                {isVerificationStep ? <label>
                                        Are you sure you want to verify this offer and its details? Once confirmed, the
                                        offer cannot be modified.
                                    </label> :
                                    <label>
                                        Are you sure you want to submit this offer for verification? After submission,
                                        only a Client Administrator will be able to modify the details.
                                    </label>}
                                <div className="mt-3 form-renderer-button-container">
                                    <Button title="Cancel" disabled={hasSubmitted} onClick={() => onDismissModal()}/>
                                    <Button title="Confirm" disabled={!isFormValid()} onClick={onConfirmSubmit}/>
                                </div>
                            </>
                            }
                        </Modal>
                        <div className="offer-button-container">
                            <Button title="Cancel" disabled={hasSubmitted} onClick={onCancel}/>
                            <div className="offer-right-buttons">
                                <Button title={!latestStatusCode ? 'Save as Draft' : 'Save And Exit'}
                                        disabled={!isValid}
                                        onClick={onDraft}/>
                                <Button title="Submit" onClick={onSubmit} disabled={!isValid}/>
                            </div>
                        </div>
                    </form>
                </div>
            </View>

  );
}

export default CreateOffer;
